<template>
  <div>
    <a-select
      v-model:value="plan"
      :placeholder="$t('Plan')"
      :filter-option="filterOption"
      :size="size"
      style="width: 100%"
      show-search
      @change="handleChange"
    >
      <a-select-option v-for="p in PLANS" :key="$t(p)" :value="p">
        {{ $t(p) }}
      </a-select-option>
    </a-select>
  </div>
</template>

<i18n>
{
  "en": {
    "Plan": "Plan",
    "standard": "Standard",
    "early-bird" : "Early Bird",
    "above-7-nights-every-7-days" : "Above 7 Nights Every 7 Days",
    "per-room-standard" : "Per Room Standard",
    "per-room-early-bird" : "Per Room Early Bird",
    "per-room-above-7-nights-every-7-days" : "Per Room Above 7 Nights Every 7 Days",
    "consecutive-stay": "Consecutive Stay",
    "with-meal": "With Meal",
  },
  "ja": {
    "Plan": "プラン",
    "standard": "スタンダードプラン",
    "early-bird" : "早割プラン",
    "above-7-nights-every-7-days" : "連泊プラン",
    "per-room-standard" : "スタンダードプラン（1室あたり）",
    "per-room-early-bird" : "早割プラン（1室あたり）",
    "per-room-above-7-nights-every-7-days" : "連泊プラン（1室あたり）",
    "consecutive-stay": "連泊プラン",
    "with-meal": "食事付プラン",
  }
}
</i18n>

<script>
import { RATE_PLANS } from '@/config/revenue-managements';

export default {
  name: 'InputPlan',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      PLANS: RATE_PLANS,
      plan: '',
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.plan = nv;
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.plan);
      this.$emit('update:value', this.plan);
    },
  },
};
</script>

<style scoped></style>
