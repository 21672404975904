<template>
  <div>
    <a-select
      v-model:value="code"
      :placeholder="$t('Calendar ID')"
      :filter-option="filterOption"
      :size="size"
      style="width: 100%"
      show-search
      @change="handleChange"
    >
      <a-select-option v-for="plan in plans" :key="plan.code" :value="plan.code">
        {{ plan.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<i18n>
{
  "en": {
    "Calendar ID": "Calendar ID",
  },
  "ja": {
    "Calendar ID": "Calendar ID",
  }
}
</i18n>

<script>
import { getResponseError } from '@/utils/util';

export default {
  name: 'InputCalendarId',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
    propertyId: {
      type: String,
      required: true,
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      code: undefined,
      plans: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.code = nv || undefined;
      },
    },
    propertyId: {
      immediate: true,
      handler(nv) {
        this.plans = [];

        this
          .handleFetch()
          .then(() => {
            const exist = this.plans.find((plan) => plan.code === this.code);

            if (nv && !exist) {
              this.code = undefined;
            }
          });
      },
    },
  },
  methods: {
    async handleFetch() {
      if (!this.propertyId) {
        return Promise.resolve();
      }

      const payload = { propertyId: this.propertyId };

      return this.$store
        .dispatch('rms-rates/neppanPlans', payload)
        .then(({ data }) => {
          this.plans = data;

          return Promise.resolve();
        })
        .catch((e) => {
          this.$message.error(getResponseError(e));
        });
    },
    filterOption(input, option) {
      return option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.code);
      this.$emit('update:value', this.code);
    },
  },
};
</script>

<style scoped></style>
